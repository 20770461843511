<template>
    <div style="min-width: 850px;">
        <!-- 设备类型 -->
        <div style="margin-bottom:20px;display:flex;align-items:center;justify-content:space-between;">
            <div class="form-title" style="margin-bottom:0;">设备信息</div>
            <el-button type="primary" size="small" @click="addDevice">新增设备</el-button>
        </div>
        <!-- 循环设备Tag -->
        <div class="custom-attr" v-for="(dItem,dIndex) in equipments" :key="dIndex">
            <div style="display: flex;justify-content: space-between;">
                <div style="display: flex;justify-content: space-between;">
                    <el-form-item label="设备名称">
                        <el-input v-model="dItem.equipmentName" size="small" style="width: 70%;" disabled placeholder="请选择设备"></el-input>
                        <el-button type="primary" size="small" @click="selectDevice(dItem)">选择设备</el-button>
                    </el-form-item>
                    <el-form-item label="点表名称">
                        <el-input v-model="dItem.triggerCondition.realData.tag" size="small" style="width: 70%;" disabled placeholder="请选择点表"></el-input>
                        <el-button type="primary" size="small" @click="selectPoint(dItem)" :disabled="!dItem.equipmentName">选择点表</el-button>
                    </el-form-item>
                </div>
                <div>
                    <div v-if="dIndex!=0" class="close-icon" @click="delDevice(dIndex)">
                        <i class="el-icon-close"></i>
                    </div>
                </div>
            </div>
            <div style="display: flex;justify-content: space-between;">
                <div style="display: flex;justify-content: space-between;">
                    <el-form-item label="点表描述">
                        <el-input v-model="dItem.describe" :disabled="!dItem.parameterCn&&!dItem.pointName" size="small" style="width: 100%;margin-right:75px;" placeholder="请输入点表描述" @change="changeDataType"></el-input>
                    </el-form-item>
                    <el-form-item label="单位" style="margin-left: 5px;">
                        <el-input v-model="dItem.unit" :disabled="!dItem.parameterCn&&!dItem.pointName" size="small" style="width: 100%;margin-right:75px;" placeholder="请输入单位" @change="changeDataType"></el-input>
                    </el-form-item>
                </div>
                <div></div>
            </div>
            <div style="display: flex;justify-content: space-between;">
                <div style="display: flex;justify-content: space-between;">
                    <el-form-item label="数据类型">
                        <el-select v-model="dItem.triggerCondition.dataType" :disabled="!dItem.parameterCn&&!dItem.pointName" size="small" style="width: 100%;margin-right:60px;" placeholder="请选择数据类型" @change="changeDataType">
                            <el-option
                                v-for="itemType in dataType"
                                :key="itemType"
                                :label="itemType"
                                :value="itemType"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="点表值类型" style="margin-left: 5px;" v-if="dItem.triggerCondition.dataType=='Real'">
                        <el-select v-model="dItem.triggerCondition.realData.formulaType" size="small" :disabled="dItem.triggerCondition.dataType!='Real'" style="width: 100%;margin-right:60px;" placeholder="请选择点表值类型" @change="changeDataType">
                            <el-option
                                v-for="item in pointValType"
                                :key="item.code"
                                :label="item.name"
                                :value="item.code"></el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <div></div>
            </div>
            <div v-if="dItem.triggerCondition.dataType=='Bool'"></div>
            <div v-if="dItem.triggerCondition.dataType=='Real'">
                <div v-if="dItem.triggerCondition.realData.formulaType=='formula'">
                    <div class="formula-title">
                        <div class="formula-title-left">配置公式</div>
                        <div class="formula-title-right">
                            <el-button v-show="dItem.triggerCondition.realData.formulaList&&dItem.triggerCondition.realData.formulaList.length>1" type="danger" size="small" @click="delTag(dItem.triggerCondition.realData.formulaList)">删除Tag</el-button>
                            <el-button type="primary" size="small" @click="addTag(dItem.triggerCondition.realData.formulaList)">新增Tag</el-button>
                        </div>
                    </div>
                    <el-row :gutter="20" style="margin: 30px 0px 0px 30px;">
                        <el-col :span="7">
                            <el-form-item label="公式项" label-width="70px">
                                <el-input v-model="dItem.triggerCondition.realData.firstInput" size="small" placeholder="请输入公式项"></el-input>
                            </el-form-item>
                        </el-col>
                        <div v-for="(val,key) in dItem.triggerCondition.realData.formulaList" :key="key">
                            <el-col :span="5">
                                <el-form-item label="Tag" label-width="50px">
                                    <el-button v-if="!val.tagDesc" class="lube-btn" type="primary" size="small" @click.prevent="selectTagItem(dItem,val)">选择Tag</el-button>
                                    <span v-else class="close-lube-btn">
                                        <span class="close-tagname">{{val.tagDesc}}</span>
                                        <span class="el-icon-error close-lube" @click="clearTagItem(val)"></span>
                                    </span>
                                </el-form-item>
                            </el-col>
                            <el-col :span="7">
                                <el-form-item label="公式项" label-width="70px">
                                    <el-input v-model="val.textName" size="small" placeholder="请输入公式项"></el-input>
                                </el-form-item>
                            </el-col>
                            <!-- 侧滑选择Tag -->
                            <el-drawer
                                title=""
                                :with-header="false"
                                :visible.sync="val.tagDrawer"
                                direction="rtl"
                                size="1000px">
                                <div class="drawer-title">选择点表</div>
                                <div class="drawer-content table-tree">
                                    <el-table :data="tagTableData" height="700" v-loading="abilityLoading" element-loading-spinner="el-icon-loading">
                                        <el-table-column label="勾选" width="70">
                                            <template slot-scope="scope">
                                            <el-radio-group v-model="val.tagFRadio" @change="checkTagChange(scope.row,dItem)">
                                                <el-radio :label="scope.row.id">{{''}}</el-radio>
                                            </el-radio-group>
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="number" label="序号" width="50"></el-table-column>
                                        <el-table-column prop="dataType" label="数据类型"></el-table-column>
                                        <el-table-column prop="pointName" label="点名"></el-table-column>
                                        <el-table-column prop="describe" label="Tag描述" width="240"></el-table-column>
                                        <el-table-column prop="readWriteType" label="读写方向"></el-table-column>
                                        <el-table-column prop="cycle" label="采集周期"></el-table-column>
                                    </el-table>
                                </div>
                                <div class="drawer-bottom">
                                    <el-button size="small" type="primary" @click="val.tagDrawer=false">取消</el-button>
                                    <el-button size="small" type="primary" @click="formulaDrawerOK(val,dItem)" class="drawer-bottom-ok">确 定</el-button>
                                </div>
                            </el-drawer>
                        </div>
                    </el-row>
                </div>
            </div>
            <!-- 选择设备类型 -->
            <el-drawer
                title=""
                :with-header="false"
                :visible.sync="dItem.drawer"
                direction="rtl"
                size="1000px">
                <div class="drawer-title">选择设备类型</div>
                <div class="drawer-content">
                    <div class="table-tree">
                        <div class="tree">
                            <div class="tree-title">
                                <div>
                                    <i class="el-icon-share" style="margin-right:10px;"></i>设备类型
                                </div>
                            </div>
                            <div class="tree-park" v-for="(item,index) in supplierData" :key="index">
                                <div class="park-title" :class="current==item.equipmentBrandCode?'bright':''" @click.prevent="brandToggle(item.equipmentBrandCode)">
                                <span class="class-icon iconfont icon-xiangmu" :style="current==item.equipmentBrandCode?'color:#FFF;':'color:#3498db;'"></span>
                                <span>{{item.equipmentBrand}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="table">
                            <el-table :data="tableData" style="width: 99%" height="700" v-loading="tableLoading" element-loading-spinner="el-icon-loading">
                                <el-table-column label="勾选" width="70">
                                    <template slot-scope="scope">
                                        <el-radio-group v-model="dItem.configRadio" @change="checkConfig(scope.row,dItem)">
                                            <el-radio :label="scope.row.eid">{{''}}</el-radio>
                                        </el-radio-group>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="waterworks" label="水厂">
                                    <template slot-scope="scope">
                                    {{ scope.row.waterworks?scope.row.waterworks:'-' }}
                                    </template>
                                </el-table-column>
                                <el-table-column prop="position" label="泵房编号">
                                    <template slot-scope="scope">
                                    {{ scope.row.position?scope.row.position:'-' }}
                                    </template>
                                </el-table-column>
                                <el-table-column prop="equipmentName" label="设备名称">
                                    <template slot-scope="scope">
                                    {{scope.row.equipmentName?scope.row.equipmentName:'-'}}
                                    </template>
                                </el-table-column>
                                <el-table-column prop="eid" label="设备号"></el-table-column>
                            </el-table>
                        </div>
                    </div>
                </div>
                <div class="drawer-bottom">
                    <el-button size="small" type="primary" @click="dItem.drawer=false">取消</el-button>
                    <el-button size="small" type="primary" @click="drawerOK(dItem)" class="drawer-bottom-ok">确 定</el-button>
                </div>
            </el-drawer>
            <!-- 选择点表 -->
            <el-drawer
                title=""
                :with-header="false"
                :visible.sync="dItem.deviceDrawer"
                direction="rtl"
                size="1000px">
                <div class="drawer-title">选择点表</div>
                <div class="drawer-content table-tree">
                    <el-table :data="tagTableData" height="700" v-loading="abilityLoading" element-loading-spinner="el-icon-loading">
                        <el-table-column label="勾选" width="70">
                            <template slot-scope="scope">
                            <el-radio-group v-model="dItem.tagRadio" @change="checkTagChange(scope.row,dItem)">
                                <el-radio :label="scope.row.id">{{''}}</el-radio>
                            </el-radio-group>
                            </template>
                        </el-table-column>
                        <el-table-column prop="number" label="序号" width="50"></el-table-column>
                        <el-table-column prop="dataType" label="数据类型"></el-table-column>
                        <el-table-column prop="pointName" label="点名"></el-table-column>
                        <el-table-column prop="describe" label="Tag描述" width="240"></el-table-column>
                        <el-table-column prop="readWriteType" label="读写方向"></el-table-column>
                        <el-table-column prop="cycle" label="采集周期"></el-table-column>
                    </el-table>
                </div>
                <div class="drawer-bottom">
                    <el-button size="small" type="primary" @click="dItem.deviceDrawer=false">取消</el-button>
                    <el-button size="small" type="primary" @click="deviceDrawerOK(dItem)" class="drawer-bottom-ok">确 定</el-button>
                </div>
            </el-drawer>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            supplierData: [],
            tableData: [],
            treeLoading: false,
            tableLoading: false,
            abilityLoading: false,
            current: null,
            selectCache: {},
            selectConfigObj: {},
            dataType: ['Real'],
            pointValType: [
                { code: 'default', name: '默认值'},
                { code: 'formula', name: '公式'},
            ],
            equipments: this.equipmentss,
            gateCurrent: null,
            devCurrent: null,
            tagCurrent: null,

            gatewayNodes: [],
            tagTableData: []
        }
    },
    props: {
        equipmentss: {
            type: Array
        },
        resource: {
            type: String
        }
    },
    methods: {
        changeDataType(e) {
            this.$forceUpdate()
        },
        changeInput(e) {
            this.$forceUpdate()
        },
        // 新增设备
        addDevice() {
            this.equipments.push({
                drawer: false,
                configRadio: '',
                deviceDrawer: false,
                tagRadio: '',
                parameterEn: null,
                parameterCn: null,
                pointName: null,

                gatewayEid:null,
                eid:null,
                equipmentBrandCode: null,
                equipmentName: null,
                describe: '',
                unit: '',
                triggerCondition: {
                    dataType:null,
                    boolData:null,
                    realData:{
                        tag:null,
                        formulaType:null,
                        firstInput:'',
                        formulaList: [
                            {
                                tagName:'',
                                tagDesc:null,
                                textName:'',
                                tagDrawer: false,
                                tagFRadio: '',
                            }
                        ]
                    }
                }
            })
            this.$forceUpdate()
        },
        // 删除设备
        delDevice(index) {
            this.equipments.splice(index,1)
        },
        // 选择设备项目
        selectDevice(dItem) {
            dItem.drawer = true
            if(dItem.equipmentBrandCode) {
                this.current = dItem.equipmentBrandCode
                this.loadListData()
            }
        },
        // 勾选设备项目
        checkConfig(row,dItem) {
            this.selectCache = {}
            dItem.configRadio = row.eid
            this.selectCache = row
        },
        // 确认选择设备项目
        drawerOK(dItem) {
            dItem.gatewayEid = this.selectCache.gatewayEid
            dItem.eid = this.selectCache.eid
            dItem.equipmentName = this.selectCache.equipmentName
            dItem.equipmentBrandCode = this.selectCache.equipmentBrandCode
            this.selectConfigObj = this.selectCache
            dItem.drawer = false
        },
        // 选择点表
        selectPoint(dItem) {
            dItem.deviceDrawer = true
            this.loadTagsList(dItem.eid)
            this.$forceUpdate()
        },
        // 选择tag赋值点表数据
        checkTagChange(row,dItem) {
            this.tagSelectObj = row
        },
        // 确定选择设备Tag
        deviceDrawerOK(dItem) {
            dItem.pointName = this.tagSelectObj.pointName
            // dItem.describe = this.tagSelectObj.describe
            dItem.triggerCondition.realData.tag = this.tagSelectObj.pointName
            this.$forceUpdate()
            dItem.deviceDrawer = false
        },
        // 新增公式中的Tag
        addTag(arr) {
            arr.push({
                tagName:'',
                tagDesc:null,
                textName:'',
                tagDrawer: false,
                tagFRadio: '',
            })
        },
        // 删除公式中的Tag
        delTag(arr) {
            arr.pop()
        },
        // 运算符循环Tag选择
        selectTagItem(dItem,val) {
            val.tagDrawer = true
            this.loadTagsList(dItem.eid)
        },
        // 运算符循环Tag清除
        clearTagItem(val) {
            val.tagName = ''
            val.tagDesc = ''
            this.$forceUpdate()
        },
        formulaDrawerOK(val,dItem) {
            val.tagName = this.tagSelectObj.pointName
            val.tagDesc = this.tagSelectObj.describe
            val.tagDrawer = false
        },
        // 获取设备
        loadListData() {
            this.tableLoading = true
            this.$ajax.post('equipmentByEcode',{
                pageNum: 0,
                pageSize: 0,
                equipmentBrandCode: this.current,
            }).then(res => {
                res.data.forEach(v=>{
                    v.check = false
                })
                this.tableData = res.data
                this.tableLoading = false
            }).catch(err=>{
                this.tableLoading = false
            })
        },
        // 切换设备项目
        brandToggle(equipmentBrandCode) {
            this.current = equipmentBrandCode
            this.equipmentBrandCode = equipmentBrandCode
            sessionStorage.setItem('equipmentBrandCode',equipmentBrandCode)
            this.loadListData()
        },
        // 获取品牌
        loadBrand() {
            this.treeLoading = true
            this.$ajax.post('equipmentGroupQuery',{
                deviceTypeCodes: ['GatewaySub']
            }).then(res => {
                if(res.data.length==0) {
                    this.treeLoading = false
                }
                this.supplierData = res.data
                if(sessionStorage.getItem('equipmentBrandCode')){
                    this.current = sessionStorage.getItem('equipmentBrandCode')
                    this.equipmentBrandCode = sessionStorage.getItem('equipmentBrandCode')
                    this.loadListData()
                }else{
                    this.current = this.supplierData[0].equipmentBrandCode
                    this.equipmentBrandCode = this.supplierData[0].equipmentBrandCode
                    this.loadListData()
                }
                this.treeLoading = false
            }).catch(err=>{
                this.treeLoading = false
            })
        },
        // 获取Tag列表
        loadTagsList(eid) {
            this.abilityLoading = true
            this.$ajax.post("iotTagsQuery", {}, { 
                eid
            }).then(res=>{
                this.tagTableData = res.data
                this.abilityLoading = false
            }).catch(err=>{
                this.abilityLoading = false
            })
        }
    },
    mounted() {
        if(this.resource=='add') {
            sessionStorage.removeItem('equipmentBrandCode')
            this.loadBrand()
        }
        if(this.resource=='edit') {
            sessionStorage.setItem('equipmentBrandCode',this.current) 
            // this.selectConfigObj.equipmentBrandCode = this.equipments.eid
            // this.configRadio = this.equipments.eid
            this.loadBrand()
        }
        this.$forceUpdate()
    }
}
</script>
<style lang="less" scoped>
.table-tree {
  display: flex;
  height: ~'calc(100% - 56px)';
  .tree {
    min-width: 300px;
    border-right: 4px solid #f6f7fb;
    margin-right: 20px;
  }
  .table {
    flex: 1;
  }
  .operate {
    &:hover {
      color: #409EFF;
    }
  }
  .custom-tree-node {
    font-size: 14px;
  }
}
/deep/.el-drawer__body {
    height: 100%;
    margin-bottom: 50px;
}
.drawer-content {
    margin: 10px 0;
    height: 86%;
    overflow-y: auto;
}
.tree-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tree-park {
  font-size: 14px;
  cursor: pointer;
}
.park-title {
  line-height: 35px;
  padding-left: 20px;
}
.class-icon {
  display: inline-block;
  margin-right: 8px;
}
.bright {
  background: #409EFF;
  color: #FFF;
  border-radius: 5px;
}
.custom-attr {
  border: 1px solid #ebeef5;
  box-shadow: 0px 0px 10px rgba(211, 211, 211, 0.5);
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 20px;
  .close-icon {
    width: 25px;
    background-color: #ff7979;
    color: #FFF;
    text-align: center;
    line-height: 25px;
    border-radius: 5px;
    cursor: pointer;
    transition: all .3s ease;
    &:hover {
        background-color: rgba(255, 121, 121, .7);
    }
  }
}
.el-select {
    width: 100%;
}
.formula-type {
    display: flex;
    align-items: center;
}
.formula-left {
    background-color: #ecf5ff;
    border: 1px solid #d9ecff;
    border-radius: 4px;
    color: #409eff;
    height: 30px;
    padding: 0 10px;
    line-height: 30px;
    font-size: 14px;
}
.f-symbol {
    font-size: 14px;
    text-align: center;
    background: #f2f2f2;
    border-radius: 5px;
    width: 30px;
    line-height: 30px;
    margin: 0 20px;
    color: #333;
}
.lube-btn {
    width: 100%;
}
.close-lube-btn {
  display: inline-block;
  text-align: center;
  width: 100%;
  height: 32px;
  color: #409EFF;
  line-height: 32px;
  border-radius: 4px;
  background: #ecf5ff;
  border-color: #409EFF;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  margin-top: 5px;
  box-sizing: border-box;
  
  .close-tagname {
    display: inline-block;
    width: 120px;
    overflow: auto;
    white-space: nowrap; 
  }
  .close-lube {
    cursor: pointer;
  }
}
.formula-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
    .formula-title-left::before {
        content: '';
        border-left: 4px solid #409EFF;
        padding-left: 10px;
    }
}
::-webkit-scrollbar {
  width: 8px;
  height: 3px;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px; 
  background-color: #c1c1c1;
  /* #58585B */
}
</style>
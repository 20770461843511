<template>
  <div>
    <el-form :model="form" label-width="100px">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="机器人">
            <div @click="addDevice()" class="staffBox">
              <div v-if="form.robotName">
                <el-tag>{{ form.robotName }}</el-tag>
              </div>
              <div v-else style="color: #909399;text-align: center;">请选择机器人</div>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <!-- 添加机器人 -->
    <el-drawer
      title="添加机器人"
      :with-header="false"
      :visible.sync="drawerDevice"
      direction="rtl"
      size="1000px">
      <div class="drawer-title">添加机器人</div>
      <div class="drawer-content table-tree">
        <div class="table">
          <el-table :data="tableDeviceData" height="700" v-loading="tableDeviceLoading" element-loading-spinner="el-icon-loading">
            <el-table-column label="勾选" width="70">
              <template slot-scope="scope">
                <el-radio-group v-model="deviceRadio" @change="checkDeviceChange(scope.row)">
                  <el-radio :label="scope.row.robotId">{{''}}</el-radio>
                </el-radio-group>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="机器人名称"></el-table-column>
            <el-table-column prop="webHook" label="机器人地址" width="370"></el-table-column>
            <el-table-column prop="remarks" label="备注"></el-table-column>
          </el-table>
        </div>
      </div>
      <div class="drawer-bottom">
        <el-button size="small" type="primary" @click="drawerDeviceCancel=false">取消</el-button>
        <el-button
          size="small"
          type="primary"
          @click="drawerDeviceOK"
          class="drawer-bottom-ok">确 定</el-button>
      </div>
    </el-drawer>
  </div>
</template>
  
<script>
export default {
  props: {
    robotId: {
      type: String
    },
    robotName: {
      type: String
    },
    resource: {
      type: String
    }
  },
  data() {
    return {
      form: {
        robotName: ''
      },
      tableData: [],
      
      // 设备类型
      drawerDevice: false,
      deviceRadio: '',
      tableDeviceData: [],
      tableDeviceSelect: {},
      tableDeviceLoading: false,
    };
  },
  methods: {
    addDevice() {
      this.loadDeviceTypeData()
      this.drawerDevice = true
    },
    // 勾选信息数据
    checkDeviceChange(row) {
      this.deviceRadio = row.robotId
      this.tableDeviceSelect = row // 勾选
    },
    drawerDeviceOK() {
      this.form.robotId = this.tableDeviceSelect.robotId
      this.form.robotName = this.tableDeviceSelect.name
      this.$emit('onRobots', this.form)
      this.drawerDevice = false
    },
    // 查询机器人
    loadDeviceTypeData() {
      this.tableDeviceLoading = true
      this.$ajax.post('entWechatRobotQuery').then(res => {
        this.tableDeviceData = res.data
        this.tableDeviceLoading = false
      }).catch(err=>{
        this.tableDeviceLoading = false
      })
    },
  },
  mounted() {
    if(this.resource=='edit') {
      this.form.robotId = this.robotId
      this.form.robotName = this.robotName
      this.deviceRadio = this.robotId
      this.tableDeviceSelect = {
        robotId: this.robotId,
        name: this.robotName,
      }
    }
  },
};
</script>
  
<style lang="less" scoped>
.table-tree {
  display: flex;
  height: ~'calc(100% - 110px)';
  margin-top: 20px;
  .tree {
    width: 220px;
    border-right: 4px solid #f6f7fb;
    margin-right: 20px;
  }
  .table {
    flex: 1;
  }
  .custom-tree-node {
    font-size: 14px;
  }
}
.staffBox {
  cursor: pointer;
  width: 490px;
  background: #fff;
  margin-right: 20px;
  height: 40px;
  border-radius: 4px;
  padding: 0 4px;
  border: 1px solid #DCDFE6;
}
</style>